/* CustomTable.css */

.custom-table-title {
  color: #4287f5;
}

.custom-table {
  border-collapse: separate;
  border-spacing: 0 15px;
  margin-top: 15px;
}

.custom-table th {
  background-color: #4287f5;
  color: white;
}

.custom-table th,
.custom-table td {
  width: 150px;
  text-align: center;
  border: 1px solid black;
  padding: 5px;
}

.dash-title {
  /* position: absolute; */
  width: 276px;
  height: 1px;
  /* left: 248px */
  /* top: 144px; */
  margin-bottom: 10px;
  margin-top: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  display: flex;
  align-items: center;

  color: #000000;
}

.dash-card-title {
  position: absolute;
  width: 156px;
  height: 13.67px;

  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 27px;
  display: flex;
  align-items: center;

  color: #000000;
}

.dash-price {
  position: absolute;
  width: 278px;
  height: 13.67px;
  margin-top: 56px;
  /* left: 263px; */
  /* top: 285.51px; */

  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 39px;
  display: flex;
  align-items: center;

  color: #000000;
}

.card-footer {
  position: absolute;
  width: 210px;
  height: 13.54px;
  /* left: 264px; */
  top: 168.21px;

  font-style: normal;
  font-weight: 450;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;

  color: #989898;
}

.ant-menu li {
  font-size: 17px;
  color: "black";
}

.image-wrapper {
  text-align: center;
  margin-bottom: 10px;
}

.image-wrapper img {
  width: 180px;
  height: 180px;
  /* object-fit: cover; */
}

.image-title {
  text-align: center;
  color: #4287f5;
  font-size: 20px;
  font-weight: 600px;
}

/* .youtube-container {
  height: 400px !important;
  width: 360px !important;} */
.youtube-container > div {
  width: 384px !important;
  height: 320px !important;
}
.youtube-container iframe {
  width: 384px !important;
  height: 320px !important;
}
.content {
  padding: 50px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0px;
  border: none;
}
.fullscreen-loader {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullscreen-loader .spinner-border {
  width: 3rem;
  height: 3rem;
  border: 0.25em solid #ffffff;
  border-right-color: transparent;
}
.selected-image {
  background-color: #eaf6ff; /* Set the desired background color */
  border: 2px solid #1890ff; /* Add a border to the selected image */
}
.cancel-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  color: black;
}

.list-table-header {
  width: 100%;
  padding: 1rem 1.25rem !important;
  border: 1px solid rgba(0,0,0,.125);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: none;
  display: flex;
  align-items: baseline;
  justify-content: center;
  font-size: 16px;
}

.list-table-column-odd {
  width: 100%;
  padding: 1rem 1.25rem !important;
  border: 1px solid rgba(0,0,0,.125);
  background-color: #F8F8F8;
  height: 60px !important; 
  border-bottom: none;
  font-size: 14px; 
  cursor: pointer;   
}


.list-table-column-even {
  width: 100%;
  padding: 1rem 1.25rem !important;
  border: 1px solid rgba(0, 0, 0, 0.125);
  background-color: #fff;
  height: 60px !important;
  border-bottom: none;
  font-size: 14px; 
  cursor: pointer;   
}

.list-table-column-last {
  
  border: 1px solid rgba(0,0,0,.125) !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  border-top: none !important;
  cursor: pointer;
}

.overflow-ellipsis{
  white-space: nowrap;      /* Prevent text from wrapping to the next line */
  overflow: hidden;         /* Hide any overflowing text */
  text-overflow: ellipsis;  /* Display ellipsis (...) for overflowing text */
  max-width: 100%;          /* Optional: Ensure the text doesn't exceed the container's width */
  cursor: pointer;
}
.pointerRow:hover {
  cursor: pointer;
  color: blue;
}
