.cancel-button {
    margin-top: 20px !important;
    position: absolute !important;
    right: 5px !important;
    color: red !important;
    background-color: #FEF9E7 !important;
    width: 36px !important;
    height: 36px !important;
    cursor: pointer !important;
    border: none !important;
}