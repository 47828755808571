.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.loading .ant-spin {
  color: #3e79f7;
}

.ant-table,
.ant-select-selection-item,
.ant-btn-primary,
.ant-input-group .ant-input {
  font-size: 14px;
}

.ant-table-wrapper {
  display: flex;
}
.ant-spin-nested-loading {
  width: 100%;
}
.ant-form-item-label > label {
  font-size: 17px;
}

.image_url label {
  width: 100%;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:active,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:hover {
  color: #fff !important;
  border-color: #3e79f7 !important;

  background: #3e79f7 !important;
  text-shadow: none !important;
  font-size: 17px !important;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12) !important;
}

.ant-menu-title-content {
  margin-left: 5px !important;
}

.anticon.anticon-api.ant-menu-item-icon {
  margin-left: 6px !important;
  margin-right: 10px !important;
}

.anticon-file-ppt.ant-menu-item-icon {
  margin-left: 6px !important;
  margin-right: 10px !important;
}

.ant-collapse-header {
  padding: 10px !important;
}

.ant-collapse-content {
  border: 1px solid rgb(230, 235, 241);
}

.nav-search-dropdown {
  max-height: 280px !important;
}

.nav-search-dropdown .rc-virtual-list-holder {
  max-height: 268px !important;
}

.justify-between {
  justify-content: space-between !important;
}

.ck .ck-content {
  min-height: 50vh !important;
}
