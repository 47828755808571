.primary-amkette-container{
    width:160px;
    height:40px
}

.primary-amkette-container-button{
    width:130px;
    height:40px
}

.secondary-amkette-container-button{
    width:80px;
    height:40px
}

.primary-button-amkette{
    width: 100%!important;
    height: 100%!important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    font-size: 14px !important;
}

.primary-button-amkette:disabled{
    width: 100% !important;
    height:100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}